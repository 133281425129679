// Libraries, components
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as vars from '../constants';
import Button from './button';
import { config } from "../env";
import axios from 'axios';
import { inject, observer } from "mobx-react";

//#region Styles
const Overflow = styled.div`
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.3);
`;
const DescriptionStyle = styled.div`
    z-index: 100;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100vw - 2rem);
    border: 1px solid ${vars.COLOR_PRIMARY};
    border-radius: .25rem;
    background-color: white;
`;
const Header = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    height: 40px;
    background: linear-gradient(90deg, ${vars.COLOR_PRIMARY}, ${vars.COLOR_PRIMARY_DARK});
    color: white;
    text-transform: uppercase;
    font-size: 1.25rem;
    text-align: center;
`;
const Content = styled.div`
    padding: .25rem;
`;
const Textarea = styled.textarea`
    border: 1px solid ${vars.COLOR_GREY_LIGHT};
    border-radius: .25rem;
    outline: none;
    width: 100%;
    padding: .5rem;
    font-size: 1rem;
`;
const Row = styled.div`
    justify-content: space-between;
    flex-direction: row;
    gap: 1rem;
    display: flex;
    padding: 0.25rem;
`;
//#endregion

function Description(props) {
    const [descriptionValue, setDescriptionValue] = useState(props.store.data.questionnaireDescription);
    const [initState, setInitState] = useState(false);
    useEffect(() => {
        if (initState) return;

        setDescriptionValue(props.store.data.questionnaireDescription);
        setInitState(true);
    }, [initState]);
    
    function onChange(event) {
        if (event.target.id === 'description') {
            setDescriptionValue(event.target.value);
        }
    }

    function saveDescription(logout) {
        axios.get(config.api.host+config.api.send_answer+'&questionnaire_id='+props.store.data.questionnaireId+'&question_id=0&value=0&description='+descriptionValue)
        .then(result => {
            props.store.data.setQuestionnaireDescription(descriptionValue);
            props.store.data.setDescriptionVisible(false);
            if (logout === true) {
                props.store.data.logout()
            }
        })
        .catch(error => {
            console.log(error);
        })
    }

    return  <Overflow><DescriptionStyle>
        <Header>
            <span>{props.isFinish ? 'Завершение' : 'Общее примечание'}</span>
        </Header>
        <Content>
        {
            props.isFinish && <>
                <Row><span>Чек-лист завершен. Редактирование будет доступно в течении текущего дня</span></Row>
                <Row><span>Общее примечание по РМ:</span>
                </Row>
            </>
        }
        <Row>
            <Textarea maxLength='500' id='description' value={descriptionValue} onChange={(event) => onChange(event)} rows='5'/>
        </Row>
        {
            !props.isFinish && <Row>
                <Button width='140px' onClick={() => props.store.data.setDescriptionVisible(false)}>Отмена</Button>
                <Button type='primary-dark' width='140px' onClick={() => saveDescription()}>Сохранить</Button>
            </Row>
        }
        {
            props.isFinish && <Row>
                <Button width='140px' onClick={() => props.store.data.setDescriptionVisible(false)}>К списку вопросов</Button>
                <Button type='primary-dark' width='140px' onClick={() => saveDescription(true)}>Завершить</Button>
            </Row>
        }
        </Content>
    </DescriptionStyle></Overflow>
}

export default inject("store")(observer(Description));