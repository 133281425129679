// Libraries, components
import styled from 'styled-components';

const RowStyle = styled.div`
    flex-direction: row;
    align-items: center;
    gap: ${props => props.gap};
    display: flex;
`;

export default function Row(props) {
    const gap = props.gap || '0';
    
    return <RowStyle gap={gap}>{props.children}</RowStyle>;
}