// Libraries, components
import React from 'react';
import styled from 'styled-components';
import ImageComponent from './image_preview';

//#region Styles
const GridStyle = styled.div`
    flex-direction: row;
    gap: .5rem;
    display: flex;
    overflow-x: auto;
`;
//#endregion

function Grid(props) {
    return  <GridStyle>{
        props.data.map((p,i) => <ImageComponent key={i} src={p} onDeletePhoto={() => props.onDeletePhoto(i)}/>)
    }</GridStyle>
}

export default Grid;