export const config = {
    api: {
        //site: 'https://checklist.kenguru.ru/',
        site: 'https://checklist.kenguru.ru/',
        host: 'https://checklist.kenguru.ru', 
        //host: 'https://10.57.20.193:8001',
        //get_checklist_data: '/api/checklist/?method=get_checklist_data',
        //check_shop: '/api/checklist/?method=check_shop',
        //get_user_data: '/api/checklist/?method=get_user_data',
        //send_answer: '/api/checklist/?method=send_answer',
        //send_file: '/api/checklist/?method=send_file', 
        //delete_file: '/api/checklist/?method=delete_file'
        get_checklist_data: '/api/?method=get_checklist_data',
        check_shop: '/api/?method=check_shop',
        get_user_data: '/api/?method=get_user_data',
        send_answer: '/api/?method=send_answer',
        send_file: '/api/?method=send_file', 
        delete_file: '/api/?method=delete_file'
    }
}