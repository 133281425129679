// Libraries, components
import React, { useState } from 'react';
import styled from 'styled-components';
import * as vars from '../constants';
import Button from './button';
import { inject, observer } from "mobx-react";
import { toJS } from 'mobx'
import { useNavigate } from "react-router-dom";

//#region Styles
const GroupComponent = styled.div`
    flex-direction: column;
    display: flex;
    border-bottom: 1px solid ${vars.COLOR_GREY_LIGHT};
    width: 100%;
    transition: ease-in 2s max-height;
`;
const GroupName = styled.span`
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 1.25rem;
    font-weight: 400;
`;
const QuestionCount = styled.span`
    color: ${props => props.isFinished ? vars.COLOR_SUCCESS : vars.COLOR_DANGER};
`;
const Header = styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
    transition: ease-in .2s background;
    gap: 1rem;
    border-bottom: ${props => props.isVisible ? '1px solid '+vars.COLOR_GREY_LIGHT : 'none'};
    padding: .5rem;
    background: linear-gradient(
        90deg,
        rgba(${vars.COLOR_PRIMARY_RGB},0.1),
        rgba(${vars.COLOR_PRIMARY_DARK_RGB},0.1));

    &:hover {
        background: linear-gradient(
            90deg,
            rgba(${vars.COLOR_PRIMARY_RGB},0.5),
            rgba(${vars.COLOR_PRIMARY_DARK_RGB},0.5));
        cursor: pointer;
    }

    &:active {
        background: linear-gradient(
            90deg,
            rgba(${vars.COLOR_PRIMARY_RGB},0.5),
            rgba(${vars.COLOR_PRIMARY_DARK_RGB},0.5));
        cursor: pointer;
    }
`;
const QuestionItem = styled.span`
    padding: .25rem .5rem;
    border-bottom: 1px solid ${props => props.isLast ? 'transparent' : vars.COLOR_GREY_LIGHT};
    background-color: ${props => props.isAnswered ? vars.COLOR_SUCCESS : 'white'};
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${props => props.isAnswered ? 'white' : vars.COLOR_TEXT};

    &:hover {
        background-color: ${vars.COLOR_GREY_LIGHT};
        cursor: pointer;
    }

    &:active {
        background-color: ${vars.COLOR_GREY_LIGHT};
        cursor: pointer;
    }
`;
const QuestionList = styled.div`
    flex-direction: column;
    display: flex;
    transition: ease-in 2s max-height;
`;
//#endregion

function GroupItem(props) {
    let navigate = useNavigate();
    const group = props.group;
    const [isVisible, setVisible] = useState(false);
    const questions = toJS(props.store.data.questions).filter(q => q.group_id === group.id);
    const answeredCount = questions.filter(q => q.value !== undefined && q.value !== null).length;

    function onClick(question) {
        navigate('/question/'+question.id);
    }

    return  <GroupComponent>
        <Header isVisible={isVisible} onClick={() => setVisible(!isVisible)}>
            <Button type='image' src={isVisible ? 'up.png' : 'down.png'} width='20px'/>
            <GroupName isLast={props.isLast}>{group.name}</GroupName>
            <QuestionCount isFinished={answeredCount === questions.length}>{answeredCount}/{questions.length}</QuestionCount>
        </Header>
        <QuestionList>
        {
            isVisible && questions && questions.length > 0 && questions.map((q, index) => 
                <QuestionItem key={'question_'+q.id} isLast={index === questions.length-1} onClick={() => onClick(q)} isAnswered={q.value !== undefined}>{q.name}</QuestionItem>
            )
        }
        </QuestionList>
    </GroupComponent>
}

export default inject("store")(observer(GroupItem));