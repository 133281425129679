// Libraries, components
import { Provider } from "mobx-react";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import App from './app';
import global from './store/global';
import data from './store/data';
import { createGlobalStyle } from 'styled-components';
import * as vars from './constants';

//#region Styles
const GlobalStyle = createGlobalStyle`
	@font-face { 
		font-family: Roboto; 
		src: url('/assets/fonts/Roboto-Regular.ttf'); 
		font-weight: 400;
    	font-style: normal;
	}
	@font-face { 
		font-family: Roboto; 
		src: url('/assets/fonts/Roboto-Medium.ttf'); 
		font-weight: 500;
    	font-style: normal;
	}
	@font-face { 
		font-family: Roboto; 
		src: url('/assets/fonts/Roboto-Bold.ttf'); 
		font-weight: 600;
    	font-style: normal;
	}
	@font-face { 
		font-family: Roboto; 
		src: url('/assets/fonts/Roboto-Italic.ttf'); 
		font-weight: 400;
    	font-style: italic;
	}
	body {
		margin: 0;
		font-family: 'Roboto';
		color: ${vars.COLOR_TEXT};

		min-height: 100vh;
    	min-height: fill-available;
    	min-height: -webkit-fill-available;
		
		-webkit-font-smoothing: antialiased;
  		-moz-osx-font-smoothing: grayscale;
		
		*, ::before, ::after {
			box-sizing: border-box;
		}
	}

	html {
		height: fill-available;
    	height: -webkit-fill-available;
	}
`;
//#endregion

const store = {
	global,
	data
}

ReactDOM.render(
    <Provider store={store}>
		<Router>
			<GlobalStyle />
			<App />
		</Router>
	</Provider>,
	document.getElementById('app')
);
