// Libraries, components
import { inject, observer } from "mobx-react";
import React from 'react';
import { Routes, Route } from "react-router-dom";
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Auth from './pages/auth';
import Main from './pages/main';
import Question from './pages/question';
import Header from "./components/header";
import Loading from "./components/loading";

//#region styles
const Page = styled.div`
    height: 100vh;
    overflow: hidden;
`;
//#endregion

class App extends React.Component {
	constructor(props) {
	super(props);
		this.state = {
		}
	}

    render() {
        const headerTitle = this.props.store.data.header_title;
        const header_type = this.props.store.data.header_type;
        const show_loading = this.props.store.data.show_loading;

        return <Page>
            {
                show_loading && <Loading />
            }
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                limit={5}/>
            <Header title={headerTitle} type={header_type}/>
            <Routes>
                <Route path="/" element={ <Main/>}/>
                <Route path="/auth" element={ <Auth/>}/>
                <Route path="/question/:id" element={ <Question/>}/>
                <Route path="*" element={ <main style={{ padding: "1rem" }}><p>Страница не найдена</p></main> }/>
            </Routes>
        </Page>
    }
}

export default inject("store")(observer(App));