// Libraries, components
import React, { useEffect, useState } from 'react';
import { inject, observer } from "mobx-react";
import styled from 'styled-components';
import * as vars from '../constants';
import AnimatedCameraImage from './animated_camera_image';
import Button from './button';
import Grid from './photos_grid';

//#region Styles
const QuestionMaterialComponent = styled.span`
    flex-direction: column;
    display: flex;
    border-bottom: 1px solid ${vars.COLOR_PRIMARY};
`;
const DescriptionInput = styled.textarea`
    width: 100%;
    border-radius: .25rem;
    border: 1px solid ${vars.COLOR_GREY_LIGHT};
    outline: none;
`;
const ButtonSection = styled.div`
    gap: 1rem;
    flex-direction: row;
    align-items: center;
    display: flex;
    border-bottom: 1px solid ${vars.COLOR_PRIMARY};
    height: 50px;
    padding: 0 .5rem;
`;
const Content = styled.div`
    display: ${props => props.visible ? 'flex' : 'none'};
    padding: 0.5rem;
`;
const Space = styled.div`
    content: ' ';
    flex: 1;
`;
//#endregion

function QuestionMaterial(props) {
    const [showDescription, setDescriptionVisible] = useState(false);
    const [showPhotos, setPhotosVisible] = useState(false);
    const [photosButtonEnable, setPhotosButtonEnable] = useState(false);
    const [descriptionValue, setDescriptionValue] = useState(props.description);
    const photos = props.photos;
    const [initState, setInitState] = useState(false);
    useEffect(() => {
        if (initState) return;
        setInitState(true);
    }, [initState]);
    useEffect(() => {
        setPhotosButtonEnable(props.photos.length > 0);
    }, [props.photos]);


    function onClickDescription() {
        setDescriptionVisible(!showDescription);
        setPhotosVisible(false);
    }

    function onClickPhotos() {
        setPhotosVisible(!showPhotos);
        setDescriptionVisible(false);
    }

    function onChange(event) {
        if (event.target.id === 'description') {
            setDescriptionValue(event.target.value);
            props.descriptionValue(event.target.value);
        }
    }

    return  <QuestionMaterialComponent>
        <ButtonSection>
        {
            photos.length !== 5 ?
                <AnimatedCameraImage  size='36px' onClick={() => props.store.global.setCameraVisible(true)}/> :
                <Space/>
        }
            <Button enable={photosButtonEnable} height='40px' onClick={() => photosButtonEnable && onClickPhotos()}>Фотографии</Button>
            <Button type='primary-dark' onClick={() => onClickDescription()}>Примечание</Button>
        </ButtonSection>
        <Content visible={showDescription || showPhotos}>
        {
            showDescription && <DescriptionInput id='description' rows='5' type='text' maxLength='50' value={descriptionValue} onChange={(event) => onChange(event)}/>
        }
        {
            showPhotos && photos.length > 0 && <Grid data={photos} {...props}/>
        }
        </Content>
    </QuestionMaterialComponent>
}

export default inject("store")(observer(QuestionMaterial));