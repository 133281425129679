// Libraries, components
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import GroupItem from "../components/group_item";
import { toJS } from 'mobx'
import * as vars from '../constants';
import Row from "../components/row";
import { useNavigate } from "react-router-dom";
import Description from "../components/modal_description";

//#region styles
const Page = styled.div`
    flex: 1;
    flex-direction: column;
    display: flex;
    height: ${vars.SIZE_CONTENT};
    overflow: hidden;
`;
const QuestionCount = styled.span`
    flex: 1;
    padding: .15rem;
    text-align: center;
`;
const QuestionList = styled.div`
    border-top: 1px solid ${vars.COLOR_PRIMARY};
    overflow-y: auto;
`;
const BoldLightSpan = styled.span`
    font-weight: 500;
`;
//#endregion

function Main(props) {
    let navigate = useNavigate();
    useEffect(() => {
        if (!props.store.data.is_authorized) {
            navigate('/auth');
        }
    })
    let groups = props.store.data.groups;
    if (groups === undefined) groups = [];
    let questions = toJS(props.store.data.questions);
    if (questions === undefined) questions = [];
    const commonQuestionCount = questions.length;
    const yesQuestionCount = questions.filter(q => parseInt(q.value) === 1).length;
    const noQuestionCount = questions.filter(q => parseInt(q.value) === 0).length;
    const [initState, setInitState] = useState(false);
    const showDescription = props.store.data.show_description;
    useEffect(() => {
        if (initState) return;

        props.store.data.setHeaderType(vars.HEADER_TYPE_MAIN);
        setInitState(true);
    }, [initState]);

    return <Page>
    { 
        showDescription && <Description isFinish={yesQuestionCount + noQuestionCount === commonQuestionCount}/>
    }
        <Row>
            <QuestionCount>Да: <BoldLightSpan>{yesQuestionCount}</BoldLightSpan></QuestionCount>
            <QuestionCount>Нет: <BoldLightSpan>{noQuestionCount}</BoldLightSpan></QuestionCount>
            <QuestionCount>Всего: <BoldLightSpan>{yesQuestionCount + noQuestionCount}/{commonQuestionCount}</BoldLightSpan></QuestionCount>
        </Row>
        {
            groups.length === 0 ? <span>Нет групп</span> : null
        }
        <QuestionList>
        {
            groups.length > 0 ? groups.map((g, index) => 
                <GroupItem group={g} isFirst={index === 0} isLast={groups.length === index + 1} key={'group_'+g.id}/>
            ) : null
        }
        </QuestionList>
    </Page>
}

export default inject("store")(observer(Main));