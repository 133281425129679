// Libraries, components
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import { useParams, Navigate, useNavigate, useLocation } from "react-router-dom";
import { config } from "../env";
import { toJS } from 'mobx'
import Camera from "../components/camera";
import styled from 'styled-components';
import axios from 'axios';
import Button from "../components/button";
import { toast } from 'react-toastify';
import * as vars from '../constants';
import QuestionMaterial from "../components/question_material";

//#region Styles
const Page = styled.div`
    flex-direction: column;
    display: flex;
    height: calc(100% - 54px);
`;
const Row = styled.div`
    justify-content: space-between;
    flex-direction: row;
    gap: ${props => props.gap || '.5rem'};
    /* height: ${props => props.is50 ? '50%' : 'auto'}; */
    
    min-height: ${props => props.isButtons ? '20%' : 'auto'};
    max-height: ${props => props.isButtons ? '50%' : 'auto'};
    display: flex;
    border-bottom: 1px solid ${props => props.hideLine ? 'transparent' : vars.COLOR_PRIMARY};
    padding: .5rem;

    @media (orientation: landscape) {
        flex: ${props => props.isButtons ? '1' : '0'};
    }
`;
//#endregion

function Question(props) {
    const params = useParams();
    const route = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (!props.store.data.is_authorized) {
            route('/auth');
        }
    })
    const [isAnswered, setAnsweredState] = useState(false);
    const [questionValue, setQuestionValue] = useState(-1);
    const isLastQuestion = props.store.data.isLastQuestion && props.store.data.lastQuestionId === params.id;
    const show_camera = props.store.global.show_camera;
    const questionnaireId = props.store.data.questionnaireId;
    const questions = props.store.data.questions;
    const question = toJS(questions).find(q => q.id === params.id);
    const allFiles = toJS(props.store.data.files.filter(f => f.question_id === question.id)) || [];
    const [photos, setPhotos] = useState([]);
    const [descriptionValue , setDescriptionValue] = useState('');
    useEffect(() => {
        if (question !== undefined && question.group !== undefined) {
            props.store.data.setHeaderType(vars.HEADER_TYPE_QUESTION);
            props.store.data.setHeaderTitle(question.group.name);
        }
    }, [question])
    useEffect(() => {
        if (question !== undefined && question.group !== undefined) {
            //получение data
            if (allFiles.length !== 0) {
                setPhotos(toJS(allFiles));
                
            }
            if (question.value !== undefined) {
                setAnsweredState(true);
                setQuestionValue(parseInt(question.value))
            }
            if (question.description !== undefined) {
                setDescriptionValue(question.description);
            }
        }
    },[]);
    if (question === null || question === undefined) {
        toast.error('Вопрос не найден');
        return (<Navigate to={'/'} state={{ from: location }} replace/>);
    }

    function onTakePhoto(dataUri) {
        const pp = [...photos]
        pp.push(dataUri);
        setPhotos(pp);
        props.store.global.setCameraVisible(false)
    }

    function deletePhoto(index) {
        const pp = [...photos];
        if (typeof pp[index] === 'object') {
            axios.get(config.api.host+config.api.delete_file+'&id='+pp[index].id)
            .then(res => {
                pp.splice(index, 1);
                setPhotos(pp);
            })
            .catch(error => {
                console.log(error)
            })
        }
        else {
            pp.splice(index, 1);
            setPhotos(pp);
        }
    }

    function nextQuestion() {
        props.store.data.setLoading(true);
        if (!isAnswered) {
            const q = toJS(questions).find(q => q.value === undefined && q.id !== question.id);
            if (q === undefined || q === null) {
                const result = window.confirm('На текущий вопрос не был дан ответ и он последний в чек-листе. Вернуться к списку вопросов?');
                if (result) {
                    props.store.data.fetchChecklistData(route, false);
                    props.store.data.setLoading(false);
                    initialState();
                    return;
                }
                else {
                    props.store.data.setLoading(false);
                    initialState();
                    return;
                }
            }
            else {
                const result = window.confirm('На текущий вопрос не был дан ответ. Перейти к следующему?');
                if (result) {
                    props.store.data.fetchChecklistData(route, true, question.order);
                    props.store.data.setLoading(false);
                    initialState();
                    return;
                }
                else {
                    props.store.data.setLoading(false);
                    initialState();
                    return;
                }
            }
        }

        
        const data = {
            method: 'send_answer',
            questionnaire_id: questionnaireId,
            question_id: question.id,
            value: questionValue,
            description: descriptionValue.replaceAll(' ', '_')
        }
        
        //axios.post(config.api.host+'/api/checklist/', data)
        axios.post(config.api.host+'/api/', data)
        .then(result => {
            toast.info(result);
            if (photos.length > 0) {
                photos.forEach((p,i) => {
                    if ((''+p).startsWith('data')) {
                        const formData = new FormData();
                        formData.append('questionnaire_id', questionnaireId);
                        formData.append('question_id', (question.id).replaceAll("ЧЛВ-",""));
                        formData.append('name', i);
                        formData.append('file', dataURItoBlob(p));
                        axios.post(config.api.host+config.api.send_file, formData)
                        .then(result => {
                            if (photos.length === i + 1) {
                                initialState();
                                props.store.data.fetchChecklistData(route, !isLastQuestion, question.order);
                                props.store.data.setDescriptionVisible(isLastQuestion)
                                //props.store.data.setLoading(false);
                                if (isLastQuestion) {
                                    route('/')
                                }
                            }
                        })
                        .catch(error => {
                            props.store.data.setLoading(false);
                            console.log(error);
                        })
                    }
                    else {
                        if (i + 1 === photos.length) {
                            initialState();
                            props.store.data.setDescriptionVisible(isLastQuestion)
                            props.store.data.fetchChecklistData(route, !isLastQuestion, question.order);
                            //props.store.data.setLoading(false);
                            if (isLastQuestion) {
                                route('/')
                            }
                        }
                    }
                })
                
            }
            else {
                initialState();
                props.store.data.setDescriptionVisible(isLastQuestion)
                props.store.data.fetchChecklistData(route, !isLastQuestion, question.order);
                //props.store.data.setLoading(false);
                if (isLastQuestion) {
                    route('/')
                }
            }
        })
        .catch(error => {
            props.store.data.setLoading(false);
            console.log(error);
            toast.error(error)
        })
    }

    function dataURItoBlob(dataURI) {
        var byteString = atob(dataURI.split(',')[1]);
    
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], {type: mimeString});
    }

    function answerQuestion(value) {
        setAnsweredState(true);
        setQuestionValue(value);
    }

    function backToMain() {
        props.store.data.setLoading(true);
        props.store.data.setRedirectToNextQuestion(false);
        if (isAnswered) {
            //let url = config.api.host+config.api.send_answer+'&questionnaire_id='+questionnaireId+'&question_id='+question.id+'&value='+questionValue+'&description='+descriptionValue.replace(' ', '_');
            //url = encodeURIComponent(url.trim())
            //toast.info(url)
            //axios.get(url)
            const data = {
                method: 'send_answer',
                questionnaire_id: questionnaireId,
                question_id: question.id,
                value: questionValue,
                description: descriptionValue.replaceAll(' ', '_')
            }
            //axios.post(config.api.host+'/api/checklist/', data)
            axios.post(config.api.host+'/api/', data)
            .then(result => {
                if (photos.length > 0) {
                    photos.forEach((p,i) => {
                        if ((''+p).startsWith('data')) {
                            const formData = new FormData();
                            formData.append('questionnaire_id', questionnaireId);
                            formData.append('question_id', (question.id).replaceAll("ЧЛВ-",""));
                            formData.append('name', i);
                            formData.append('file', dataURItoBlob(p));
                            axios.post(config.api.host+config.api.send_file, formData)
                            .then(result => {
                                if (photos.length === i + 1) {
                                    props.store.data.fetchChecklistData(route, false);
                                    props.store.data.setLoading(false);
                                }
                            })
                            .catch(error => {
                                props.store.data.setLoading(false);
                                console.log(error);
                            })
                        }
                        else {
                            if (i + 1 === photos.length) {
                                props.store.data.fetchChecklistData(route, false);
                                props.store.data.setLoading(false);
                            }
                        }
                    })
                    
                }
                else {
                    props.store.data.fetchChecklistData(route, false);
                    props.store.data.setLoading(false);
                }
            })
            .catch(error => {
                props.store.data.setLoading(false);
                console.log(error);
                toast.error(error)
            })
        }
        else {
            const result = window.confirm('На текущий вопрос не был дан ответ. Вернуться к списку вопросов?');
            if (result) {
                props.store.data.fetchChecklistData(route, false);
                props.store.data.setLoading(false);
            }
            else {
                props.store.data.setLoading(false);
                return;
            }
        }
    }

    function initialState() {
        setDescriptionValue('');
        setAnsweredState(false);
        setQuestionValue(-1);
        setPhotos([])
    }

    const propsDescriptionValue = (value) => {
        setDescriptionValue(value);
    }

    return <Page key={question !== undefined && question.id}>
        {
            show_camera && <Camera onTakePhoto={onTakePhoto}/>
        }
        <Row gap='2rem'>
            <Button onClick={() => backToMain()}>К списку вопросов</Button>
            <Button onClick={() => nextQuestion()}type='primary-dark'>{isLastQuestion ? 'Завершить' : 'Далее'}</Button>
        </Row>
        <Row>
            <span>{question.name}</span>
        </Row>
        { 
            isAnswered && <QuestionMaterial descriptionValue={propsDescriptionValue} photos={photos} description={descriptionValue} onDeletePhoto={deletePhoto}/>
        }
        <Row hideLine={true} is50={true} isButtons={true}>
            <Button active={isAnswered && questionValue === 1} activeColor={vars.COLOR_PRIMARY} type='big' onClick={() => answerQuestion(1)}>Да</Button>
            <Button active={isAnswered && questionValue === 0} activeColor={vars.COLOR_PRIMARY_DARK} type='big-dark' onClick={() => answerQuestion(0)}>Нет</Button>
        </Row>
    </Page>
}

export default inject("store")(observer(Question));