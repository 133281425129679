// Libraries, components
import React from 'react';
import styled, { keyframes } from 'styled-components';

//#region Styles
const animation = keyframes`
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-25%);
    }
`;
const animationTwo = keyframes`
    from {
        left: -75%;
    }
    to {
        left: 75%;
    }
`;
const LoadingComponent = styled.span`
    position: absolute;
    z-index: 100000;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.5);
`;
const Back = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    gap: 0.5rem;
    border-radius: .5rem;
    padding: 1rem;
    width: 70vw;
    text-align: center;
    overflow: hidden;
`;
const Kangaroo = styled.img`
    position: relative;
    animation: ${animationTwo} 2s linear infinite, ${animation} 0.25s ease-out alternate infinite;
`
//#endregion

function Loading(props) {
    return  <LoadingComponent>
        <Back>
        <Kangaroo src='/kangaroo.png' height='64px' width='64px'/>
        <span>Подождите, выполняется операция</span>
        </Back>
    </LoadingComponent>
}

export default Loading;