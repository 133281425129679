// Libraries, components
import { inject, observer } from "mobx-react";
import React from 'react';
import {BrowserMultiFormatReader, NotFoundException} from '@zxing/library';
import styled, {keyframes} from 'styled-components';
import { toast } from 'react-toastify';
import axios from 'axios';
import Row from '../components/row';
import * as vars from '../constants';
import Label from "../components/label";
import Button from "../components/button";
import { withRouter } from "../components/router_component";
import { config } from "../env";

//#region Styles
const pulse = keyframes`
    0% { border: 1px solid white; }
    50% { border: 1px solid ${vars.COLOR_GREY}; }
    100% { border: 1px solid white; }
`;
const Page = styled.div`
    flex-direction: column;
    gap: 1rem; 
    display: flex;
    /*height: ${vars.SIZE_CONTENT};*/
    padding: .5rem;
    overflow: hidden;
`;
const Select = styled.select`
    flex: 1;
    border: 1px solid ${vars.COLOR_PRIMARY};
    border-radius: .25rem;
    padding: .25rem;
    background-color: white;
    outline: none;
`;
const CameraPreview = styled.video`
    animation: ${pulse} 2s ease infinite;
    /*flex: 1;*/
    border-radius: .5rem;
    max-height: 50vh;
    width: 100%;
    object-fit: cover;

    @media (orientation: landscape) {
        height: 50%;
    }
`;
const Input = styled.input`
    flex: 1;
    border-top: none;
    border-bottom: 1px solid ${vars.COLOR_PRIMARY};
    border-left: none;
    border-right: none;
    padding: .25rem;
    text-align: center;
    font-size: 1.1rem;
    color: ${vars.COLOR_PRIMARY};
    outline: none;
`;
const LargerText = styled.span`
    text-align: center;
    font-size: 1.5rem;
    color: ${vars.COLOR_TEXT_SECONDARY};
`;
const RefreshButton = styled.img`
    margin: 0 .5rem;
`;
//#endregion Styles

const codeReader = new BrowserMultiFormatReader();
class Auth extends React.Component {
	constructor(props) {
	super(props);
		this.state = {
            badgeNumber: '',
            shopId: 0,
            qrCode: '',
            helpText: 'Выберите камеру',
            state: 0,
            selectedDevice: null,
            codeReader: null,
            devices: [],
            sourceSelect: null,
            questions: []
		}

        this.login = this.login.bind(this);
        this.onChange = this.onChange.bind(this);
        this.changeState = this.changeState.bind(this);
        this.fetchAuth = this.fetchAuth.bind(this);
        this.fetchCheckShop = this.fetchCheckShop.bind(this);
	}

    render() {
        const {
            badgeNumber,
            helpText,
            state,
            devices
        } = this.state;

        return <Page>
            <Row gap='1rem'>
                <Label labelFor='selectedDevice'>Камера:</Label>
                <Select id='selectedDevice' onChange={(event) => this.onChange(event)}>
                    <option hidden key='selectedDevice_0'>...</option>
                {
                    devices.length > 0 && devices.map((device,index) => <option value={device.id} key={'selectedDevice_'+index}>{device.label}</option>)
                }
                </Select>
                <RefreshButton onClick={() => this.refresh()}src='/refresh.png' alt='Обновить состояние' height='16px' width='16px'/>
            </Row>
            <LargerText>{helpText}</LargerText>
            <CameraPreview id='cameraPreview' /*height='900px' width='1800px'*/>
                Ваш браузер не поддерживает отображение видео.
            </CameraPreview>
            {
                state !== vars.STATE_SHOP ?
                <>
                    <Row gap='1rem'>
                        <Label size='1.1rem'>Пропуск:</Label>
                        <Input
                            id='badgeNumber'
                            type='number'
                            value={badgeNumber}
                            max={999999}
                            min={1}
                            step={1}
                            placeholder='Номер пропуска сотрудника'
                            onChange={this.onChange}/>
                    </Row>
                    <Button onClick={() => this.login()}>Войти</Button>
                </> : null
            }
        </Page>
    }

    onChange(event) {
        if (event.target.id === 'badgeNumber') {
            if (event.target.value !== '') {
                const str = (event.target.value).toString().replace(/[0-9]/g, '');
                if (str.length === 0) {
                    const value = parseInt(event.target.value);
                    if (isNaN(value)) {
                        toast.error('Некорректный пропуск')
                        return;
                    }
                    if (value < -1 || value > 99999) {
                        toast.error('Некорректный пропуск')
                        return;
                    }
                }
                else {
                    toast.error('Некорректный пропуск')
                    return;
                }
            }
        }
        this.setState({ [event.target.id]: event.target.value });
        
        if (event.target.id === 'selectedDevice') {
            this.changeState(vars.STATE_BADGE);
            this.startCodeReader(event.target.value);
        }
	}

    login() {
        this.props.store.data.setLoading(true);
        const { badgeNumber } = this.state;

        if (this.checkBadgeNumber(badgeNumber)) {
            this.fetchAuth(badgeNumber);
            return;
        }
        else {
            this.props.store.data.setLoading(false);
        }
    }

    /**
     * Изменение состояния компонента.
     * Состояния (число):
     * 0 - Не выбрана камера
     * 1 - Не отсканирован пропуск
     * 2 - Не отсканирован QR-код магазина
     * @param {int} state Состояние
     */
    changeState(nextState) {
        const { state } = this.state;

        let ns;
        if (nextState === null || nextState === undefined)
            ns = state;
        else
            ns = nextState;

        if (ns === vars.STATE_CAMERA) {
            this.setState({
                helpText: 'Выберите камеру1',
                state: vars.STATE_CAMERA
            });
            return;
            
        }

        if (ns === vars.STATE_BADGE) {
            this.setState({
                helpText: 'Отсканируйте номер пропуска',
                state: vars.STATE_BADGE
            });
            return;
        }

        if (ns === vars.STATE_SHOP) {
            this.setState({
                helpText: 'Отсканируйте QR-код магазина',
                state: vars.STATE_SHOP
            });
        }
    }

    checkBadgeNumber(badgeNumber) {
        if (badgeNumber === null || badgeNumber === undefined || badgeNumber === '') {
            toast.error('Не заполнен номер пропуска')
            return false;
        }

        const str = (badgeNumber).toString().replace(/[0-9]/g, '');
        if (str.length === 0) {
            const value = parseInt(badgeNumber);
            if (isNaN(value)) {
                toast.error('Некорректный пропуск')
                return false;
            }
            if (value < -1 || value > 99999) {
                toast.error('Некорректный пропуск')
                return false;
            }
        }
        else {
            toast.error('Некорректный пропуск')
            return false;
        }

        return true;
    }

    checkQR(qrCode) {
        if (qrCode === undefined) {
            toast.error('QR-код не определен');
            return false;
        }

        if (qrCode === '') {
            toast.error('QR-код пустой');
            return false;
        }

        let shopObject = null;
        try {
            shopObject = JSON.parse(qrCode);
        }
        catch (error) {
            toast.error('Неправильный QR-код');
            return false;
        }

        if (shopObject === undefined || shopObject === null) {
            toast.error('Неправильный QR-код');
            return false;
        }
        if (shopObject.br === undefined || shopObject.br === null || shopObject.br === '') {
            toast.error('Неправильный QR-код');
            return false;
        }

        return true;
    }

    fetchAuth(badgeNumber) {

        axios.get(config.api.host+config.api.get_checklist_data+'&pass_number='+badgeNumber)
        .then(result => {
            if (result.status === 204) {
                toast.error('Пользователь не идентифицирован. Необходимо подать заявку на регистрацию.');
                this.props.store.data.setLoading(false);
                return;
            }

            const user = result.data.user_data;
            user.pass = badgeNumber;

            this.setState({badgeNumber: badgeNumber});

            this.props.store.data.setUserData(user);
            this.props.store.data.setGroups(result.data.groups);
            this.props.store.data.setQuestions(result.data.questions);

            this.setState({questions: result.data.questions});

            localStorage.setItem('badgeNumber', badgeNumber);
            
            this.changeState(this.state.selectedDevice === null ? vars.STATE_CAMERA : vars.STATE_SHOP);
            this.props.store.data.setLoading(false);
        }) 
        .catch(error => {
            console.log(error);
            this.props.store.data.setLoading(false);
        })
    }

    fetchCheckShop(shopId) {
        if (shopId === undefined) {
            toast.error('Номер магазина не определен');
            this.props.store.data.setLoading(false);
            return;
        }

        axios.get(config.api.host+config.api.check_shop+'&shop_id='+shopId)
        .then(result => {
            const shopData = result.data;

            if (parseInt(shopData.status) === 0) {
                toast.error('Отсканирован QR-код неработающего магазина');
                this.props.store.data.setLoading(false);
                return;
            }

            shopData.id = shopId;
            this.props.store.data.setShopData(shopData);
            this.setState({shopId: shopId});
            codeReader.reset();
            this.props.store.data.fetchChecklistData(this.props.router.navigate, true);
        }) 
        .catch(error => {
            console.log(error.message);
            this.props.store.data.setLoading(false);
        })
    }

    componentDidMount() {
        this.initCodeReader();

        this.props.store.data.setHeaderType(vars.HEADER_TYPE_ONLY_TITLE);
        this.props.store.data.setHeaderTitle('Чек-лист');
        this.setState({badgeNumber: localStorage.getItem('badgeNumber') || ''});
    }

    initCodeReader() {
        codeReader.listVideoInputDevices()
        .then((videoInputDevices) => {
            const dl = [];
            dl.push({id: videoInputDevices[0].deviceId, label: videoInputDevices[0].label});
            if (videoInputDevices.length >= 1) {
                for(let i = 1; i < videoInputDevices.length; i++) {
                const element = videoInputDevices[i];
                dl.push({id: element.deviceId, label: element.label});
                }
            }
            this.setState({devices: dl})
        })
        .catch((err) => {
            console.error(err)
        })
    }

    startCodeReader(deviceId) {
        codeReader.decodeFromVideoDevice(deviceId, 'cameraPreview', (result, err) => {
            if (result) { 
              const { state } = this.state;
                if (state === vars.STATE_BADGE || state === vars.STATE_CAMERA) {
                    
                    if (result.text.length === 8 && !isNaN(result.text)) {
                        const badgeNumber = parseInt(result.text.substr(0, result.text.length - 1));
                        this.setState({
                            badgeNumber: badgeNumber
                        });
                    }
                }

                if (state === vars.STATE_SHOP) {
                    if (this.checkQR(result.text)) {
                        const shopObject = JSON.parse(result.text);
                        if (shopObject.br !== undefined) {
                            this.props.store.data.setLoading(true);
                            this.fetchCheckShop(shopObject.br);
                        }
                        else {
                            toast.error('Отсканирован неправильный QR-код магазина');
                            this.props.store.data.setLoading(false);
                        }
                    }
                }
            }
            if (err && !(err instanceof NotFoundException)) {
              console.error(err)
              this.setState({badgeNumber: ''});
              this.props.store.data.setLoading(false);
            }
        });
    }

    refresh() {
        codeReader.reset();
        this.setState({badgeNumber: '',
            shopId: 0,
            qrCode: '',
            helpText: 'Выберите камеру',
            state: 0,
            selectedDevice: null,
            codeReader: null,
            devices: [],
            sourceSelect: null,
            questions: []
        });
        this.props.store.data.setLoading(false);
        this.initCodeReader();
    }
}

const f = withRouter(Auth);
export default inject("store")(observer(f));