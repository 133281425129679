// Libraries, components
import { action, makeObservable, observable, toJS } from "mobx";
import axios from 'axios';
import { config } from "../env";
import * as vars from '../constants';

class Data {
    is_authorized = false;
    user = {};
    shop = {};
    groups = [];
    questions = [];
    questionnaireId = 2;
    questionnaireDescription = '';
    files = [];
    answers = [];
    redirectToNextQuestion = false;
    header_title = 'Чек-лист';
    header_only_title = false;
    header_type = vars.HEADER_TYPE_ONLY_TITLE;
    show_description = false;
    show_loading = false;
    isLastQuestion = false;
    lastQuestionId = null;

    constructor() {
        makeObservable(this, {
            is_authorized: observable,
            header_title: observable,
            header_only_title: observable,
            header_type: observable,
            show_loading: observable,
            groups: observable,
            questions: observable,
            questionnaireId: observable,
            questionnaireDescription: observable,
            files: observable,
            answers: observable,
            redirectToNextQuestion: observable,
            isLastQuestion: observable,
            lastQuestionId: observable,
            setUserData: action,
            setGroups: action,
            setQuestions: action,
            setQuestionnaireDescription: action,
            setQuestionnaireId: action,
            setAnswers: action,
            setFiles: action,
            setHeaderTitle: action,
            setHeaderType: action,
            fetchChecklistData: action,
            setRedirectToNextQuestion: action,
            show_description: observable,
            setLoading: action,
            setLastQuestionId: action
        });

        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept";

        this.setUserData = this.setUserData.bind(this);
        this.setShopData = this.setShopData.bind(this);
        this.setGroups = this.setGroups.bind(this);
        this.setQuestions = this.setQuestions.bind(this);
        this.setQuestionnaireDescription = this.setQuestionnaireDescription.bind(this);
        this.setQuestionnaireId = this.setQuestionnaireId.bind(this);
        this.setAnswers = this.setAnswers.bind(this);
        this.setFiles = this.setFiles.bind(this);
        this.fetchChecklistData = this.fetchChecklistData.bind(this);
        this.setRedirectToNextQuestion = this.setRedirectToNextQuestion.bind(this);
        this.logout = this.logout.bind(this);
        this.setHeaderTitle = this.setHeaderTitle.bind(this);
        this.setHeaderType = this.setHeaderType.bind(this);
        this.setDescriptionVisible = this.setDescriptionVisible.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.setLastQuestionId = this.setLastQuestionId.bind(this);
    }

    setLastQuestionId(id) {
        this.lastQuestionId = id;
    }

    setLoading(state) {
        if (state === undefined) {
            this.show_loading = false;
        }

        this.show_loading = state;
    }

    setUserData(data) {
        this.user = data;
    }

    setShopData(data) {
        this.shop = data;
    }

    setDescriptionVisible(state) {
        if (state === undefined) {
            this.show_description = !this.show_description;
            return;
        }
        
        this.show_description = state;
    }

    setGroups(groups) {
        this.groups = groups;
    }

    setQuestions(questions, isSimple) {
        if (isSimple === undefined) {
            const g = toJS(this.groups);
            questions.forEach(q => {
                const gr = g.find(g => g.id === q.group_id);
                if (gr !== null && gr !== undefined) {
                    q.group = gr;
                }
            })
        }

        this.questions = questions;
    }

    setQuestionnaireId(id) {
        this.questionnaireId = id;
    }

    setQuestionnaireDescription(value) {
        this.questionnaireDescription = value;
    }

    setFiles(files) {
        this.files = files;
    }

    setAnswers(answers) {
        answers.forEach(a =>  {
            const qs = toJS(this.questions);
            const index = qs.findIndex(function q(v) { 
                return v.id === this.question_id
            }, a);
            if (index !== -1) {
            const question = qs[index];
            question.value = a.value;
            question.description = a.description;
            
            qs[index] = question;
            this.setQuestions(qs);
            }
        });
    }

    fetchChecklistData(navigateFunction, redirect, currentOrder) {
        this.setLoading(true)
        const pass = this.user.pass;
        const id = this.shop.id;
        axios.get(config.api.host+config.api.get_user_data+'&pass_number='+pass+'&shop_id='+id)
        .then(result => {
            this.setQuestionnaireId(result.data.checklist.questionnaire_id);
            this.setQuestionnaireDescription(result.data.checklist.description);
            if (result.data.answers.length > 0) {
                result.data.answers.forEach((a,i) =>  {
                    const index = this.questions.findIndex(v => {return v.id === a.question_id});
                    if (index !== -1) {
                        const qs = this.questions;
                        const question = qs[index];
                        question.value = a.value;
                        question.description = a.description;
                        
                        qs[index] = question;
                        this.setQuestions(qs);
                    }

                    if (i + 1 === result.data.answers.length) {
                        this.redirectToNextQuestion = true;
                        this.is_authorized = true;
                    }
                });
            }
            else {
                this.redirectToNextQuestion = true;
            }
            this.setFiles(result.data.files);
            this.is_authorized = true;
            
            this.isLastQuestion = this.questions.filter(q => q.value === undefined).length === 1;
            if (this.isLastQuestion) {
                const lastQuestion = this.questions.find(q => q.value === undefined);
                this.setLastQuestionId(lastQuestion.id);
            }

            if (navigateFunction !== undefined && navigateFunction !== null) {
                if (redirect !== undefined && !redirect) {
                    this.setHeaderType(vars.HEADER_TYPE_MAIN);
                    navigateFunction('/')
                    this.setLoading(false)
                    return;
                }
                let newQ = toJS(this.questions);
                
                if (currentOrder !== undefined) {
                    newQ = newQ.filter(qw => parseInt(qw.order) > parseInt(currentOrder));
                }
                newQ.sort(function(a,b) {
                    return a.order - b.order;
                });

                const unanws = newQ.filter(qw => qw.value === undefined || qw.value === null);
                let nextq = null;
                if (unanws !== undefined && unanws.length === 0) {
                    if (currentOrder !== undefined) {
                        nextq = newQ.find(q => parseInt(q.order) > parseInt(currentOrder));
                    }
                }
                else {
                    nextq = newQ.find(q => q.value === undefined || q.value === null);
                    if (nextq === undefined || nextq === null) {
                        newQ = toJS(this.questions);
                        newQ.sort(function(a,b) {
                            return parseInt(a.order) - parseInt(b.order);
                        });
                        nextq = newQ.find(q => q.value === undefined || q.value === null);
                    }
                }

                if (nextq !== undefined && nextq !== null && redirect) {
                    this.setHeaderType(vars.HEADER_TYPE_QUESTION);
                    navigateFunction('/question/'+nextq.id);
                    this.setLoading(false)
                }
                else {
                    this.setHeaderType(vars.HEADER_TYPE_MAIN);
                    navigateFunction('/')
                    this.setLoading(false)
                }
            }
            
        })
        .catch(error => {
            console.log(error.message);
            this.setLoading(false)
        })
    }

    setRedirectToNextQuestion(state) {
        this.redirectToNextQuestion = state;
    }

    setHeaderTitle(title) {
        this.header_title = title;
    }


    setHeaderType(type) {
        this.header_type = type;
    }

    logout() {
        this.is_authorized = false;
        this.user = {};
        this.shop = {};
        this.groups = [];
        this.questions = [];
        this.questionnaireId = 0;
        this.questionnaireDescription = '';
        this.files = [];
        this.answers = [];
        this.redirectToNextQuestion = false;
        this.show_description = false;
    }
}

export default new Data();