import React, { useEffect, useState } from 'react';
import { inject, observer } from "mobx-react";
import styled from 'styled-components';
import Webcam from "react-webcam";
import * as vars from '../constants';

//#region Style
const CameraComponentStyle = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    overflow: auto;
    background-color: black;
`;
const CancelButton = styled.span`
    position: absolute;
    top: 0;
    right: 0;
    font-size: 2rem;
    padding: 1rem;
    z-index: 101;
    cursor: pointer;
    color: white;
`;
const videoConstraints = {
    width: 1920,
    height: 1080,
    facingMode: { exact: "environment" }
  };
const Butt = styled.button`
    position: fixed;
    bottom: 0.5rem;
    left: 50%;
  	transform: translate(-50%, -50%);
    padding: .5rem;
    background-color: ${vars.COLOR_PRIMARY};
    color: white;
    outline: none;
    font-size: larger;
    border: none;
    text-transform: uppercase;
    border-radius: .25rem;
    z-index: 150;
`;
//#endregion

function CameraComp (props) {
    const webcamRef = React.useRef(null);
    const ref = React.useRef(null);
    const [width, setWidth] = useState();
  	const [height, setHeight] = useState();
    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot({width, height});
        if (imageSrc) {
            props.onTakePhoto(imageSrc);
        }
    }, [webcamRef]);

    useEffect(() => {
        setHeight(ref.current.offsetHeigth);
        setWidth(ref.current.offsetWidth);
    }, [ref])

    return <CameraComponentStyle ref={ref}>
		<Webcam audio={false}
			height='100%'
			width='100%'
			ref={webcamRef}
			screenshotFormat="image/jpeg"
			videoConstraints={videoConstraints}
			screenshotQuality='0.9'/>
		<Butt onClick={capture}>Снимок</Butt>   
		<CancelButton onClick={() => props.store.global.setCameraVisible(false)}>X</CancelButton>  
    </CameraComponentStyle>
}

export default inject("store")(observer(CameraComp));