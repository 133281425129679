// Libraries, components
import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import * as vars from '../constants';

//#region Style
const DefaultButton = styled.button`
    align-items: center;
    justify-content: center;
    border: 1px solid ${props => props.border};
    border-radius: .25rem;
    line-height: 100%;
    height: ${props => props.height};
    width: ${props => props.width};
    padding: 0 .5rem; 
    background-color: ${props => props.enable ? props.background : vars.COLOR_GREY_LIGHT};
    font-size: 1rem;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    display: flex;
    color: ${props => props.color};
    text-transform: uppercase;
    cursor: pointer;
    outline: none;
    text-align: center;
`;
const Image = styled.img`
    cursor: pointer;
`;
const Big = styled.button`
    flex: 1;
    border: none;
    border-radius: .5rem;
    height: ${props => props.height}px;
    background-color: ${props => props.active ? props.activeColor : vars.COLOR_GREY};
    font-size: 36px;
    transition: ease-in .2s;
    text-transform: uppercase;
    color: white;
    cursor: pointer;
    outline: none;

    &:hover {
        background-color: ${props => props.isDark ? vars.COLOR_PRIMARY_DARK : vars.COLOR_PRIMARY};
    }

    @media (orientation: landscape) {
        height: auto;
    }
`;
const BigTest = styled.div`
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: .5rem;
    width: 50%;
    transition: ease-in .2s;
    font-size: 36px;
    background-color: ${props => props.active ? props.activeColor : vars.COLOR_GREY};
    text-transform: uppercase;
    color: white;
    cursor: pointer;
    outline: none;

    @media (orientation: landscape) {
        width: 50%;
        height: auto;
    }

    ::after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    &:hover {
        background-color: ${props => props.isDark ? vars.COLOR_PRIMARY_DARK : vars.COLOR_PRIMARY};
    }
`;
//#endregion

export default function Button(props) {
    const myRef = useRef();
    const [buttonWidth, setButtonWidth] = useState(0);
    const height = props.height || '40px';
    const enable = props.enable || true;
    const width = props.width || 'auto';
    useEffect(() => {
        if (myRef.current) {
            setButtonWidth(myRef.current.offsetWidth)
        }
    }, []);

    switch (props.type || 'primary') {
        case 'big':
            //return <Big ref={myRef} height={buttonWidth} {...props}>{props.children}</Big>;
            return <BigTest {...props}>{props.children}</BigTest>
        case 'big-dark':
            //return <Big ref={myRef} height={buttonWidth} isDark={true} {...props} >{props.children}</Big>;
            return <BigTest isDark={true} {...props}>{props.children}</BigTest>
        case 'image':
            return <Image src={props.src || ''} width={props.width || '16px'} height={props.height || props.width || '16px'} {...props}/>
        case 'primary-dark':
            return <DefaultButton 
                width={width}
                enable={enable}
                border={vars.COLOR_PRIMARY_DARK}
                background={vars.COLOR_PRIMARY_DARK}
                height={height}
                color='white' {...props}>
                {props.children}
            </DefaultButton>;
        default:
            return <DefaultButton 
                width={width}
                enable={enable}
                border={vars.COLOR_PRIMARY}
                background={vars.COLOR_PRIMARY}
                height={height}
                color='white' {...props}>
                {props.children}
            </DefaultButton>;
    }
}