// Libraries, components
import styled from 'styled-components';

const LabelStyle = styled.label`
    font-size: ${props => props.size};
`;

export default function Label(props) {
    const size = props.size || '1rem';
    const labelFor = props.labelFor || '';
    if (labelFor !== '')
        return <LabelStyle size={size} htmlFor={labelFor}>{props.children}</LabelStyle>;
    else
        return <LabelStyle size={size}>{props.children}</LabelStyle>;
}