import React from 'react';
import 'react-html5-camera-photo/build/css/index.css';
import styled from 'styled-components';
import { config } from '../env';

const Component = styled.div`
    position: relative;
`;
const ImageComponentStyle = styled.img`
    width: 90px;
    height: auto;
    object-fit: cover;
    border-radius: .25rem;
`;
const DeleteButton = styled.span`
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    font-size: larger;
    color: red;
    cursor: pointer;
`;

function ImageComponent (props) {
    const isObject = typeof props.src === 'object';

    return <Component>
        <DeleteButton onClick={props.onDeletePhoto}>X</DeleteButton>
        <ImageComponentStyle src={isObject ? config.api.host+props.src.path : props.src} />
    </Component>
}

export default ImageComponent;