// Libraries, components
import { action, makeObservable, observable } from "mobx";
import * as vars from '../constants';

class Main {
    is_authorized = false;
    user_name = '';
    user_role = '';
    header_title = '';
    header_only_title = false;
    header_type = vars.HEADER_TYPE_ONLY_TITLE;
    show_camera = false;
    show_description = false;

    constructor() {
        makeObservable(this, {
            is_authorized: observable,
            user_name: observable,
            user_role: observable,
            header_title: observable,
            header_only_title: observable,
            header_type: observable,
            show_camera: observable,
            show_description: observable,
            change: action,
            setHeaderTitle: action,
            setCameraVisible: action,
            setDescriptionVisible: action,
            setAuth: action
        });

        this.change = this.change.bind(this);
        this.setAuth = this.setAuth.bind(this);
        this.setHeaderTitle = this.setHeaderTitle.bind(this);
        this.setCameraVisible = this.setCameraVisible.bind(this);
        this.setDescriptionVisible = this.setDescriptionVisible.bind(this);
    }

    change() {
        this.is_authorized = true;
        this.header_type = vars.HEADER_TYPE_MAIN;
    }

    setHeaderTitle(title) {
        this.header_title = title;
    }

    setCameraVisible(state) {
        this.show_camera = state;
    }

    setHeaderType(type) {
        this.header_type = type;
    }

    setDescriptionVisible(state) {
        if (state === undefined) {
            this.show_description = !this.show_description;
            return;
        }
        
        this.show_description = state;
    }

    setAuth(state) {
        this.is_authorized = state;
    }
}

export default new Main();