// Libraries, components
import React from 'react';
import styled, { keyframes } from 'styled-components';

//#region Styles
const animation_one = keyframes`
    0% { opacity:1; }
    47% { opacity:1; }
    48% { opacity:0.66; }
    49% { opacity:0.33; }
    50% { opacity: 0}
    97% { opacity:0; }
    98% { opacity:0.33; }
    99% { opacity:0.66; }
    100% { opacity:1; }
`;
const animation_two = keyframes`
    0% { opacity:0; }
    47% { opacity:0; }
    48% { opacity:0.33; }
    49% { opacity:0.66; }
    50% { opacity: 1;}
    97% { opacity:1; }
    98% { opacity:0.66; }
    99% { opacity:0.33; }
    100% { opacity:0; }
`;
const Component = styled.div`
    flex: 1;
    width: ${props => props.size};
    height: ${props => props.size};
`;
const ImageComponent = styled.img`
    position: absolute;
    animation: ${props => props.animation} 6s ease-in infinite;
    animation-delay: ${props => props.delay};
    width: ${props => props.size};
    height: ${props => props.size};
`;
//#endregion

function AnimatedCameraImage(props) {
    return  <Component size={props.size || '24px'} {...props}>
        <ImageComponent animation={animation_one} size={props.size || '24px'} src='/camera_1.png'/>
        <ImageComponent animation={animation_two} size={props.size || '24px'}src='/camera_2.png'/>
    </Component>
}

export default AnimatedCameraImage;