//#region Colors
export const COLOR_PRIMARY = '#FF9500';
export const COLOR_PRIMARY_RGB = '255, 149, 0';
export const COLOR_PRIMARY_DARK = '#EA5E20';
export const COLOR_PRIMARY_DARK_RGB = '234, 94, 32';
export const COLOR_SECONDARY = '#EA5E20';
export const COLOR_GREY = '#828282';
export const COLOR_GREY_LIGHT = '#BDBDBD';
export const COLOR_SUCCESS = '#07A241';
export const COLOR_DANGER = '#EB5757'
export const COLOR_TEXT = '#333333';
export const COLOR_TEXT_SECONDARY = COLOR_GREY;
//#endregion

//#region Sizes
export const SIZE_HEADER = '54px';
export const SIZE_FOOTER = '30px';
export const SIZE_CONTENT = 'calc(100% - '+SIZE_HEADER+')';
//#endregion

// Other
/** Версия приложения */
export const VERSION = '0.1.0';
export const STATE_CAMERA = 0;
export const STATE_BADGE = 1;
export const STATE_SHOP = 2;
export const HEADER_TYPE_ONLY_TITLE = 0;
export const HEADER_TYPE_MAIN = 1;
export const HEADER_TYPE_QUESTION = 2;