// Libraries, components
import { inject, observer } from "mobx-react";
import React from 'react';
import styled from 'styled-components';
import * as vars from '../constants';
import Button from "./button";

//#region Styles
const HeaderComponent = styled.div`
    flex-direction: row;
    display: flex;
    justify-content: ${props => props.left !== undefined ? 'left' : 'center'};
    text-align: ${props => props.left !== undefined ? 'left' : 'center'};
    align-items: center;
    height: ${vars.SIZE_HEADER};
    width: 100%;
    padding: .5rem;
    background: linear-gradient(90deg, ${vars.COLOR_PRIMARY}, ${vars.COLOR_PRIMARY_DARK});
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
    color: white;
`;
const InfoSection = styled.div`
    flex: 1;
    flex-direction: column;
    display: flex;
    text-align: left;
`;
const InfoShop = styled.span`
    font-size: 1.25rem;
    font-weight: 600;
`;
const InfoName = styled.span`
    font-size: 1rem;
    font-weight: 400;
`;
//#endregion

function Header(props) {
    if (props === null || undefined) {
        return null;
    }

    const type = props.type || vars.HEADER_TYPE_ONLY_TITLE;
    if (type === vars.HEADER_TYPE_ONLY_TITLE) {
        return <HeaderComponent><span>{props.title || ''}</span></HeaderComponent>
    }

    if (type === vars.HEADER_TYPE_QUESTION) {
        return <HeaderComponent left><span>{props.title || ''}</span></HeaderComponent>
    }

    if (type === vars.HEADER_TYPE_MAIN) {
        const userData = props.store.data.user;
        const shopData = props.store.data.shop;
        const showButton = props.showButton || true;
        
        return <HeaderComponent>
            <InfoSection>
                <InfoShop>{shopData.name || ''}</InfoShop>
                <InfoName>{userData.full_name || ''}</InfoName>
            </InfoSection>
            {
                showButton && <Button type='image' onClick={() => props.store.data.setDescriptionVisible()} src='/description.png' width='28px'/>
            }
        </HeaderComponent>
    }
}

export default inject("store")(observer(Header));